@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: auto;
}

body {
  background-color: #212121;
}

#root {
  height: 100%;
  width: 100%;
}
